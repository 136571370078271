import type { NextPage } from 'next'
import Screenshot from '../lib/components/Screenshot'
import PageHeader from '../lib/components/page-header'
import BodyText from '../lib/components/body-text'
import { signIn } from 'next-auth/react'

const Home: NextPage = () => {
  return (
    <>
      <PageHeader title="A little AI-powered plugin to inspire you and easily sprout new prototypes in Figma design" />
      <div className="my-9 flex justify-center">
        <a
          href="https://www.figma.com/community/plugin/1225838911769955229/sprouts.design%3A-AI-prototypes"
          className="mx-2 flex w-[180px] cursor-pointer items-center justify-center rounded-full bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow hover:bg-green-500"
        >
          <img src="/figma.svg" className="mr-2 h-5" /> Try on Figma
        </a>
        <a
          onClick={() => signIn('figma', { callbackUrl: '/account' })}
          className="mx-2 w-[180px] cursor-pointer rounded-full bg-green-400 px-4 py-2 text-center text-sm text-slate-50 shadow hover:bg-green-300"
        >
          Get more credits
        </a>
      </div>
      <div className="flex-start flex flex-col">
        <h3 className="mt-4">🌰 Seed your design</h3>
        <BodyText>Give the plugin an idea of what to generate.</BodyText>
        <div className="mt-2">
          <Screenshot src="step1.png" />
        </div>
      </div>
      <div className="flex-start flex flex-col">
        <h3 className="mt-8">🌱 AI sprouts a usable design</h3>
        <BodyText>
          Powered by the latest AI models, the{' '}
          <strong className="text-sm font-semibold text-slate-700">sprouts.design</strong> AI takes your input and
          generates a prototype&mdash;all backed native components in Figma.
        </BodyText>
        <div className="mt-2">
          <Screenshot src="step2b.png" />
        </div>
      </div>
      <div className="flex-start flex flex-col">
        <h3 className="mt-8">🪴 Grow your design into your own</h3>
        <BodyText>
          <strong className="text-sm font-semibold text-slate-700">sprouts.design</strong> reduces the time it takes to
          get started with an idea so you can quickly get to creating your own designs.
        </BodyText>
        <div className="mt-2">
          <Screenshot src="step3.png" />
        </div>
      </div>
      <div className="flex-start flex flex-col">
        <h3 className="mt-8">🌳 Keep iterating until perfect</h3>
        <BodyText>
          Tweak your results using <strong className="text-sm font-semibold text-slate-700">refinements</strong> or
          generate again to get new results!
        </BodyText>
        <div className="mt-2">
          <Screenshot src="refinement-example.gif" />
        </div>
      </div>
    </>
  )
}

export default Home
